import { properties } from '@awdis/components'
import styled from 'styled-components'

const { MAIN_CONTAINER_WIDTH, MAIN_CONTAINER_SIDE_PADDING } = properties

const MainContainer = styled.main`
  margin: 0 auto;
  max-width: ${MAIN_CONTAINER_WIDTH}px;
  padding: 0px ${MAIN_CONTAINER_SIDE_PADDING}px 20px;
`

export default MainContainer

import styled from 'styled-components'
import { colours } from '@awdis/components'

const DistributorName = styled.strong`
  display: block;
  color: ${colours.GREY};
  font-size: 1rem;
  font-weight: 500;
`

export default DistributorName

import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { colours } from '@awdis/components'

type BasicLinkProps = {
  hoverEffect?: boolean
}

const hoverStyle = css`
  &:hover {
    color: ${colours.BROWNISH_GREY};
  }
`

const linkStyle = css<BasicLinkProps>`
  color: ${colours.BLACK};
  text-decoration: none;
  transition: 0.2s color;

  ${({ hoverEffect = true }) => (hoverEffect ? hoverStyle : '')};
`

const BasicLink = styled.a`
  ${linkStyle}
`

export const RouterBasicLink = styled(Link)`
  ${linkStyle}
`

export default BasicLink

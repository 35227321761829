import * as React from 'react'
import styled from 'styled-components'
import { colours, properties } from '@awdis/components'

type CardContainerProps = {
  fill?: boolean
}

const CardContainer = styled(({ fill, ...props }) => <div {...props} />)<
  CardContainerProps
>`
  align-items: center;
  border: 1px solid ${colours.INNER_SHADOW};
  display: flex;
  flex-flow: row nowrap;
  max-width: ${({ fill = false }: CardContainerProps) =>
    fill ? 'none' : `${properties.MAIN_CONTAINER_WIDTH}px`};
  padding: 1em;
  width: 100%;
  transition: background-color 0.2s ease-in-out;
  &:hover {
    background-color: #fafafa;
  }
`

export default CardContainer

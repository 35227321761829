import { createGlobalStyle } from 'styled-components'
import { colours } from '@awdis/components'

import suisseLight from '../../../../assets/fonts/suisseintl/SuisseIntl-Light.woff'
import suisseLightItalic from '../../../../assets/fonts/suisseintl/SuisseIntl-LightItalic.woff'

import suisseRegular from '../../../../assets/fonts/suisseintl/SuisseIntl-Regular.woff'
import suisseRegularItalic from '../../../../assets/fonts/suisseintl/SuisseIntl-RegularItalic.woff'

import suisseMedium from '../../../../assets/fonts/suisseintl/SuisseIntl-Medium.woff'
import suisseMediumItalic from '../../../../assets/fonts/suisseintl/SuisseIntl-MediumItalic.woff'

import suisseSemiBold from '../../../../assets/fonts/suisseintl/SuisseIntl-SemiBold.woff'
import suisseSemiBoldItalic from '../../../../assets/fonts/suisseintl/SuisseIntl-SemiBoldItalic.woff'

import suisseBold from '../../../../assets/fonts/suisseintl/SuisseIntl-Bold.woff'
import suisseBoldItalic from '../../../../assets/fonts/suisseintl/SuisseIntl-BoldItalic.woff'

import suisseBlack from '../../../../assets/fonts/suisseintl/SuisseIntl-Black.woff'
import suisseBlackItalic from '../../../../assets/fonts/suisseintl/SuisseIntl-BlackItalic.woff'

const FontDefinition = createGlobalStyle`
  @font-face {
    font-family: Suisse;
    font-weight: normal;
    src: url(${suisseRegular}) format('woff');
  }

  @font-face {
    font-family: Suisse;
    font-style: italic;
    font-weight: normal;
    src: url(${suisseRegularItalic}) format('woff');
  }

  @font-face {
    font-family: Suisse;
    font-weight: 300;
    src: url(${suisseLight}) format('woff');
  }

  @font-face {
    font-family: Suisse;
    font-style: italic;
    font-weight: 300;
    src: url(${suisseLightItalic}) format('woff');
  }

  @font-face {
    font-family: Suisse;
    font-weight: 500;
    src: url(${suisseMedium}) format('woff');
  }

  @font-face {
    font-family: Suisse;
    font-style: italic;
    font-weight: 500;
    src: url(${suisseMediumItalic}) format('woff');
  }

  @font-face {
    font-family: Suisse;
    font-weight: 600;
    src: url(${suisseSemiBold}) format('woff');
  }

  @font-face {
    font-family: Suisse;
    font-style: italic;
    font-weight: 600;
    src: url(${suisseSemiBoldItalic}) format('woff');
  }

  @font-face {
    font-family: Suisse;
    font-weight: bold;
    src: url(${suisseBold}) format('woff');
  }

  @font-face {
    font-family: Suisse;
    font-style: italic;
    font-weight: bold;
    src: url(${suisseBoldItalic}) format('woff');
  }

  @font-face {
    font-family: Suisse;
    font-weight: 800;
    src: url(${suisseBlack}) format('woff');
  }

  @font-face {
    font-family: Suisse;
    font-style: italic;
    font-weight: 800;
    src: url(${suisseBlackItalic}) format('woff');
  }

  html {
    font-family: 'Suisse';
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${colours.BLACK}
  }

  h1, h2, h3, h4 {
    font-family: Suisse;
    font-weight: bold;
  }
`

export default FontDefinition

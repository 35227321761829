import { colours } from '@awdis/components'
import styled from 'styled-components'
import Cell, { HeaderCell } from '../Cell'

const Row = styled.tr`
  &:first-child > ${HeaderCell}, &:first-child > ${Cell} {
    border-top: 1px solid ${colours.BROWNISH_GREY};
  }

  &:last-child > ${HeaderCell}, &:last-child > ${Cell} {
    border-bottom: 1px solid ${colours.BROWNISH_GREY};
  }
`

export default Row

import styled from 'styled-components'

const CardText = styled.p`
  font-family: Suisse;
  font-size: 0.8rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d6d;
`
export default CardText

import * as React from 'react'

import Html from '../Html'
import Panel from '../Panel'
import Card, { ICard } from '../Card'
import CardList from './components/CardList'

interface IHtmlCards {
  html: string
  cards: Array<ICard>
}

type Props = IHtmlCards
const HtmlCards = ({ html, cards }: Props) => {
  return (
    <Panel>
      <section>
        <Html html={html} />
      </section>

      <section>
        <CardList>
          {cards.map((card, index) => (
            <Card key={index} {...card} />
          ))}
        </CardList>
      </section>
    </Panel>
  )
}

export default HtmlCards

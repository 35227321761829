import * as React from 'react'
import styled from 'styled-components'
import { Social } from '../../../../../../services/types/social'
import GoogleTranslate from '../../../Header/components/GoogleTranslate'
import { MenuTitle, MenuWrapper } from '../FooterMenu'
import SocialLink from '../SocialLink'

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  > a {
    text-decoration: none;
    margin-right: 10px;
    justify-content: flex-start;
  }
`

const IconGoogleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
`

type Props = {
  social: Social
}

class FooterMenuSocial extends React.Component<Props> {
  state = {
    googleStatus: false,
  }
  componentDidMount() {
    window.addEventListener('resize', this.resize)
    this.resize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }
  resize = () => {
    const { googleStatus } = this.state
    if (!googleStatus && window.innerWidth < 768) {
      this.setState({ googleStatus: true })
    }

    if (googleStatus && window.innerWidth > 768) {
      this.setState({ googleStatus: false })
    }
  }
  render() {
    const { social } = this.props
    const { googleStatus } = this.state
    return (
      <MenuWrapper>
        <MenuTitle>Connect with Us</MenuTitle>
        <IconGoogleWrapper>
          <IconContainer>
            {Object.keys(social).map(key => (
              <SocialLink key={key} socialName={key} href={social[key]} />
            ))}
          </IconContainer>
          {googleStatus && <GoogleTranslate />}
        </IconGoogleWrapper>
      </MenuWrapper>
    )
  }
}

export default FooterMenuSocial

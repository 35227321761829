import { truncateUrl } from '@awdis/components'
import * as React from 'react'
import styled from 'styled-components'
import { secondaryTextStyle } from '../DistributorPhone'

type DistributorWebsiteProps = {
  children: string
  className?: string
}

const defaultProps = {}

const DistributorWebsiteC = function DistributorWebsite({
  children: url,
  ...props
}: DistributorWebsiteProps) {
  // Truncate url
  const truncatedUrl = truncateUrl(url)
  return <div {...props}>{truncatedUrl}</div>
}

DistributorWebsiteC.defaultProps = defaultProps

const DistributorWebsite = styled(DistributorWebsiteC)`
  ${secondaryTextStyle}
`

export default DistributorWebsite

import { Budicon, IProduct } from '@awdis/components'
import * as algoliasearch from 'algoliasearch'
import Downshift, { GetItemPropsOptions } from 'downshift'
import * as React from 'react'
import { RouterBasicLink } from '../BasicLink'
import {
  ClearButton,
  DetailsContainer,
  FeaturedImage,
  IconSubstitute,
  ImageShadow,
  Item,
  ItemCode,
  ItemName,
  Label,
  Menu,
  MenuContainer,
  SearchBarContainer,
  SearchBarWrapper,
} from './styles'

const client = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_API_KEY
)
const index = client.initIndex(
  `${process.env.GATSBY_BRAND.toLowerCase().replace(/ /g, '')}-products`
)

type Props = {
  styles?: {
    opacity: number
    height: number | string
    padding: number
    marginBottom: number
  }
}

type State = {
  isOpen: boolean
  isSmall: boolean
  searchResults: Array<IProduct>
}

class SearchBar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      isOpen: false,
      isSmall: false,
      searchResults: [],
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize)
    this.resize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  resize = () => {
    // On resize, if large screen open menu and searcher
    // else
    // close all
    const { isSmall } = this.state
    if (!isSmall && window.innerWidth > 768) {
      this.setState({ isSmall: true })
    }

    if (isSmall && window.innerWidth < 768) {
      this.setState({ isSmall: false })
    }
  }

  handleInputChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    index.search(
      {
        query: value,
      },
      (err, content) => {
        if (err) throw console.error(err)

        this.setState({
          isOpen: value === '' ? false : true,
          searchResults: content.hits,
        })
      }
    )
  }

  renderSearchResults = (
    results: Array<IProduct>,
    getItemProps: (options: GetItemPropsOptions<object>) => {},
    highlightedIndex: number,
    selectedItem: IProduct
  ) => {
    return results.map((item, index) => (
      <RouterBasicLink
        key={index}
        to={`products/${item.code}`}
        hoverEffect={false}
      >
        <Item
          {...getItemProps({
            item,
            index,
          })}
          isSelected={selectedItem && selectedItem.id === item.id}
          isActive={highlightedIndex === index}
          noborder={index === results.length - 1}
        >
          {item.featuredImage ? (
            <FeaturedImage src={item.featuredImage} />
          ) : (
            <ImageShadow />
          )}
          <DetailsContainer>
            <ItemName>{item.name}</ItemName>
            <ItemCode>{item.code}</ItemCode>
          </DetailsContainer>
        </Item>
      </RouterBasicLink>
    ))
  }

  render() {
    const { isOpen, isSmall, searchResults } = this.state

    return (
      <Downshift
        onOuterClick={() => this.setState({ isOpen: false })}
        itemToString={item => (item ? item.name : '')}
      >
        {({
          getLabelProps,
          getInputProps,
          getItemProps,
          clearSelection,
          selectedItem,
          inputValue,
          highlightedIndex,
        }) => (
          <div
            style={
              this.props.styles && {
                display: 'flex',
                justifyContent: 'flex-end',
                opacity: !isSmall && this.props.styles.opacity,
                height: !isSmall && this.props.styles.height,
                padding: !isSmall && this.props.styles.padding,
                marginBottom: !isSmall && this.props.styles.marginBottom,
                overflow: !isSmall && !isOpen && 'hidden',
              }
            }
          >
            <SearchBarWrapper>
              <Label {...getLabelProps()}>Search</Label>
              <SearchBarContainer noborderbot={isOpen}>
                <Budicon name="search" size="small" />
                <input
                  {...getInputProps({
                    placeholder: 'Search Products',
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                      this.handleInputChange(e),
                  })}
                />
                {inputValue ? (
                  <ClearButton
                    onClick={() => {
                      clearSelection()
                      this.setState({ isOpen: false })
                    }}
                    aria-label="clear selection"
                  >
                    <Budicon name="cross-ui" size="medium" />
                  </ClearButton>
                ) : (
                  <IconSubstitute />
                )}
              </SearchBarContainer>

              <MenuContainer>
                {!isOpen ? null : (
                  <Menu>
                    {this.renderSearchResults(
                      searchResults,
                      getItemProps,
                      highlightedIndex,
                      selectedItem
                    )}
                  </Menu>
                )}
              </MenuContainer>
            </SearchBarWrapper>
          </div>
        )}
      </Downshift>
    )
  }
}

export default SearchBar

import { SemanticLink, Title } from '@awdis/components'
import * as React from 'react'
import styled from 'styled-components'
import { checkIsInternalUrl } from '../../services/utils/check'
import SemanticRouterLink from '../SemanticRouterLink'
import TextContainer from '../TextContainer'
import HeroTextBlock from './components/HeroTextBlock'

export type SectionProps = {
  thickness?: 'thin' | 'medium' | 'thick' | 'fill'
  title: string
  image?: string
  backgroundColour?: string
  text?: string
  linkUrl?: string
  style?: object
  className?: string
  fluid?: boolean
}

const Text = styled.p`
  position: relative;
  z-index: 1;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  font-family: Suisse;
  font-size: 1.0625rem;
  font-weight: 500;
  color: #ffffff;
  align-self: flex-start;
  margin-bottom: 0;
  text-align: left;
`

const Gradient = styled.div`
  position: absolute;
  left: 0;
  top: 200px;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.72) 100%
  );

  z-index: 0;
`

const Section = ({
  image,
  title,
  text,
  linkUrl,
  style = {},
  className,
  fluid,
  backgroundColour,
  ...props
}: SectionProps) => {
  const commonProps = {
    className: !linkUrl ? className : undefined,
    style: !linkUrl ? style : { height: '100%' },
    fill: fluid,
  }

  let LinkComponent: React.ComponentType<any>
  let linkProps
  const isInternalUrl = linkUrl && checkIsInternalUrl(linkUrl)
  if (isInternalUrl) {
    LinkComponent = SemanticRouterLink
    linkProps = { to: linkUrl }
  } else {
    LinkComponent = SemanticLink
    linkProps = { href: linkUrl }
  }

  const content = image ? (
    <TextContainer
      alignItems="flex-start"
      padding="15px 30px"
      backgroundColour={backgroundColour}
      backgroundImage={image}
      column
      contentPosition="bottom"
      gradient
      {...commonProps}
      {...props}
    >
      {linkUrl && <Gradient />}
      <Title inverted size="tiny" textShadow>
        {title}
      </Title>
      {text && <Text>{text}</Text>}
    </TextContainer>
  ) : (
    <HeroTextBlock
      backgroundColour={backgroundColour}
      {...commonProps}
      {...props}
    >
      <Title as="strong" size="big">
        {title}
      </Title>
    </HeroTextBlock>
  )

  return linkUrl ? (
    <LinkComponent className={className} {...linkProps} style={style} fluid>
      {content}
    </LinkComponent>
  ) : (
    content
  )
}

export default Section

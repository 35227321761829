import * as React from 'react'
import styled, { css } from 'styled-components'
import { TemplateType } from '../../services/types/pages'
import AccordionTemplate from '../AccordionTemplate'
import CallToAction from '../CallToAction'
import Card from '../Card'
import ContactForm from '../ContactForm'
import DistributorAccordions from '../DistributorAccordions'
import DistributorsMap from '../DistributorsMap'
import Header from '../Header'
import Html from '../Html'
import HtmlAccordions from '../HtmlAccordions'
import HtmlCards from '../HtmlCards'
import HtmlForm from '../HtmlForm'
import HtmlHtml from '../HtmlHtml'
import HtmlImage from '../HtmlImage'
import ImageImage from '../ImageImage'
import InternationalSizeGuide from '../InternationalSizeGuides'
import ResourceCards from '../ResourceCards'
import Section from '../Section'
import Carousel from '../TemplateCarousel'

type TemplateComponentProps = {
  type: TemplateType
}

const templateStyle = css`
  margin-bottom: 30px;
`

const TemplateCallToAction = styled(CallToAction)`
  ${templateStyle}
`

const TemplateCarousel = styled(Carousel).attrs({
  aspectRatio: 1150 / 400,
})`
  ${templateStyle}
`

class TemplateComponent extends React.PureComponent<TemplateComponentProps> {
  static defaultProps = {}

  /**
   * Gets the appropriate component function to use based on the provided type.
   * @param type { TemplateType } The type of component.
   * @returns { ReactType } The appropriate React component for the given type.
   * Defaults to `div` if the type is unrecognised.
   */
  static getTemplateComponent(
    type: TemplateType
  ): string | React.ComponentType<any> {
    let component: string | React.ComponentType<any>

    switch (type) {
      case TemplateType.Accordion: {
        component = AccordionTemplate
        break
      }

      case TemplateType.CallToAction: {
        component = TemplateCallToAction
        break
      }

      case TemplateType.Card: {
        component = Card
        break
      }

      case TemplateType.Carousel: {
        component = TemplateCarousel
        break
      }

      case TemplateType.DistributorAccordions: {
        component = DistributorAccordions
        break
      }

      case TemplateType.DistributorMap: {
        component = DistributorsMap
        break
      }

      case TemplateType.Form: {
        component = ContactForm
        break
      }

      case TemplateType.Header: {
        component = Header
        break
      }

      case TemplateType.Html: {
        component = Html
        break
      }

      case TemplateType.HtmlAccordions: {
        component = HtmlAccordions
        break
      }

      case TemplateType.HtmlImage: {
        component = HtmlImage
        break
      }

      case TemplateType.HtmlHtml: {
        component = HtmlHtml
        break
      }

      case TemplateType.HtmlForm: {
        component = HtmlForm
        break
      }

      case TemplateType.HtmlCards: {
        component = HtmlCards
        break
      }

      case TemplateType.Image: {
        component = Section
        break
      }

      case TemplateType.SizeGuide: {
        component = InternationalSizeGuide
        break
      }

      case TemplateType.ImageImage: {
        component = ImageImage
        break
      }

      case TemplateType.ResourceCards: {
        component = ResourceCards
        break
      }

      default: {
        component = 'div'
        console.warn(`Unrecognised TemplatePage component type '${type}'`)
      }
    }

    return component
  }

  render() {
    const { type, ...props } = this.props

    const Component = TemplateComponent.getTemplateComponent(type)

    return <Component {...props} />
  }
}

export default TemplateComponent

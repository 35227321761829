import * as React from 'react'

import LinkButton from '../../../../../LinkButton'
import { Button, Modal } from '@awdis/components'
import styled from 'styled-components'

type UsaModalProps = {
  brandName: string
  toggleModal: () => void
  toggle: boolean
}

const Container = styled.div`
  padding: 25px;
  padding-bottom: 0;
  text-align: center;
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  > button,
  a {
    min-width: 120px;
    margin: 6px;
    padding-left: 0;
    padding-right: 0;
  }
`

const SecondaryButton = styled(Button)`
  font-size: 14px;
  border-color: transparent;
`

const UsaModal = ({ brandName, toggleModal, toggle }: UsaModalProps) => (
  <Modal handleCloseModal={toggleModal} size="medium" open={toggle}>
    <Container>
      <h2>We noticed you are from the USA</h2>
      <ButtonContainer>
        <LinkButton
          href={
            brandName === 'Just Hoods'
              ? 'https://justhoodsusa.com/'
              : 'https://justcoolusa.com/'
          }
        >
          Visit US site
        </LinkButton>
        <SecondaryButton onClick={toggleModal}>No, thank you</SecondaryButton>
      </ButtonContainer>
    </Container>
  </Modal>
)

export default UsaModal

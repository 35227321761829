import { Budicon, SemanticButton } from '@awdis/components'
import * as React from 'react'
import axios from 'axios'

import { Link } from 'gatsby'
import { Transition } from 'react-spring'
import { Menu } from '../../../../services/types/menu'

import SearchBar from '../../../SearchBar'
import GoogleTranslate from './components/GoogleTranslate'
import NavBar from './components/NavBar'

import {
  ForCenterTheLogo,
  GoogleTranslateContainer,
  HeaderContainer,
  HeaderWrapper,
  Logo,
  RightHeaderContainer,
} from './components/styles'

import UsaModal from './components/UsaModal'

import { getClientIP, getCountryCode } from '../../../../services/api/location'

type Props = {
  siteTitle?: string
  menu: Array<Menu>
  logo?: string
  brandName: string
  isNewIn?: boolean
}
type State = {
  navBarStatus: boolean
  searchBarStatus: boolean
  isBig: boolean
  prevWidth: number
  toggle: boolean
}

const isUS = (code: string) => code === 'US' || false

class Header extends React.Component<Props, State> {
  state = {
    navBarStatus: false,
    searchBarStatus: false,
    hideNav: '',
    isBig: false,
    prevWidth: 0,
    toggle: false,
  }

  componentDidMount() {
    const { brandName } = this.props

    this.redirectUser(brandName)

    window.addEventListener('resize', this.resize)

    this.resize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  resize = () => {
    const { prevWidth } = this.state
    // On resize, if large screen open menu and searcher
    // else
    // close all
    if (window && window.innerWidth > 768 && prevWidth <= 768) {
      this.setState({
        navBarStatus: true,
        searchBarStatus: true,
        isBig: true,
        prevWidth: window.innerWidth,
      })
    }
    if (window && window.innerWidth < 768 && prevWidth >= 768) {
      this.setState({
        navBarStatus: false,
        searchBarStatus: false,
        isBig: false,
        prevWidth: window.innerWidth,
      })
    }
  }

  handleDisplayMenu = () => {
    this.setState(({ navBarStatus }) => ({
      navBarStatus: !navBarStatus,
      searchBarStatus: false,
    }))
  }

  handleDisplaySearch = () => {
    this.setState(({ searchBarStatus }) => ({
      searchBarStatus: !searchBarStatus,
      navBarStatus: false,
    }))
  }

  async redirectUser(brandName: string) {
    const getUrl = window.location.href
    const isHome = !getUrl.match(/^(?:[^\/]*\/){3}([^\/]*)/)[1]

    // * Display the modal only when the brand name is Just Hoods or Just Cool and land page its home
    if (
      brandName &&
      (brandName.toLowerCase() === 'just hoods' ||
        brandName.toLowerCase() === 'just cool') &&
      isHome
    ) {
      // * Get country from local storage
      const userCountry = localStorage.getItem('userCountry')

      // * If Country is stored on the local storage
      if (userCountry) {
        // * If Country is US
        if (isUS(userCountry)) {
          this.setState({
            toggle: true,
          })
        }
      } else {
        // * If Country is NOT stored on the local storage
        try {
          // * Fetch IP Address
          const ip = await getClientIP()

          // * Fetch country based on IP
          const countryCode = await getCountryCode(ip)

          if (countryCode) {
            // * Store the country
            localStorage.setItem('userCountry', countryCode)
          }

          // * If country is US
          if (isUS(countryCode)) {
            this.setState({
              toggle: true,
            })
          }
        } catch (error) {
          console.error(error)
        }
      }
    }
  }

  render() {
    const { navBarStatus, searchBarStatus, isBig, toggle } = this.state
    const { menu, logo, brandName, isNewIn } = this.props

    return (
      <HeaderWrapper>
        <UsaModal
          brandName={brandName}
          toggleModal={() => this.setState({ toggle: !toggle })}
          toggle={toggle}
        />
        <HeaderContainer>
          <SemanticButton onClick={this.handleDisplayMenu}>
            <Budicon
              size="medium"
              name={navBarStatus ? 'cross-ui left' : 'hamburger-ui left'}
            />
          </SemanticButton>
          <ForCenterTheLogo />
          <Link
            to="/"
            style={{
              color: 'black',
              textDecoration: 'none',
            }}
          >
            {logo ? <Logo src={logo} alt="Brand Logo" /> : <h1>{brandName}</h1>}
          </Link>
          <SemanticButton onClick={this.handleDisplaySearch}>
            {searchBarStatus ? (
              <Budicon name="cross-ui right" size="medium" />
            ) : (
              <Budicon name="search right" size="medium" />
            )}
          </SemanticButton>
          {isBig && (
            <RightHeaderContainer>
              <GoogleTranslateContainer>
                <GoogleTranslate />
              </GoogleTranslateContainer>
              <SearchBar />
            </RightHeaderContainer>
          )}
        </HeaderContainer>

        <Transition
          items={navBarStatus}
          from={{
            opacity: isBig ? 1 : 0,
          }}
          enter={{ opacity: 1 }}
          leave={{
            display: 'none',
          }}
        >
          {navBarStatus =>
            navBarStatus &&
            (props => <NavBar styles={props} menu={menu} isNewIn={isNewIn} />)
          }
        </Transition>

        {!isBig && (
          <Transition
            items={searchBarStatus}
            from={{
              opacity: 0,
              height: 0,
              padding: 'initial',
              marginBottom: 40,
            }}
            enter={{
              opacity: 1,
              height: 'auto',
              padding: 'initial',
              marginBottom: 20,
            }}
            leave={{
              opacity: 0,
              height: 0,
              padding: 0,
              marginBottom: 0,
            }}
          >
            {searchBarStatus =>
              searchBarStatus && (props => <SearchBar styles={props} />)
            }
          </Transition>
        )}
      </HeaderWrapper>
    )
  }
}
export default Header

import { Budicon, SemanticButton, properties } from '@awdis/components'
import styled from 'styled-components'
import { RouterBasicLink } from '../../../../../BasicLink'

const { ResponsiveBreakpoints, zIndex } = properties

interface ISelected {
  selected: boolean
}

interface IWithItems extends ISelected {
  haveItems: boolean
}

export const ArrowIcon = styled(Budicon)``

export const FirstUlContainer = styled.ul`
  list-style: none;
  margin: 0;
  border-bottom: 1px solid #e9e9e9;
  position: relative;
  z-index: ${zIndex.normal};
  padding: 0 0 0 20px;

  @media (min-width: ${ResponsiveBreakpoints.Small}) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border: 0;
    margin-bottom: 40px;
    order: 3;
    padding: 0px;
    z-index: ${zIndex.negative};
  }
`

export const FirstLiContainer = styled.li`
  border-top: 1px solid #e9e9e9;
  font-weight: bold;
  margin: 0px;
  padding: 0px;
  position: relative;
  position: relative;
  cursor: pointer;
  &:first-child {
    border-top: 0;
  }
  &:last-child {
    border-bottom: 0;
  }

  &:after {
    transform: ${(props: ISelected): string | null =>
      props.selected && 'rotate(89deg)'};
  }

  @media (min-width: ${ResponsiveBreakpoints.Small}) {
    border: 0;
    margin: 0 20px;
    background-color: ${(props: ISelected): string | null =>
      props.selected && 'initial'};

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      > ul {
        display: block;
      }
    }
  }

  @media (max-width: ${ResponsiveBreakpoints.Medium}) {
    margin: 0;
    margin-right: 15px;
  }
`

export const LinkArrowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  background-color: ${(props: IWithItems): string | null =>
    props.selected && '#e9e9e9'};

  > a,
  p {
    margin: 0;
    text-decoration: none;
    color: initial;
    position: relative;
    z-index: 20;
    align-self: end;
    font-size: 1.125rem;
    font-weight: 500;
  }

  > ${ArrowIcon} {
    margin-right: 15px;
  }

  @media (min-width: ${ResponsiveBreakpoints.Small}) {
    padding-bottom: ${(props: IWithItems) => (props.haveItems ? '5px' : '3px')};
    ${ArrowIcon} {
      display: none;
    }
    a {
      border-bottom: ${(props: IWithItems) =>
        props.haveItems ? 'none' : '2px solid transparent'};
      &:hover {
        border-bottom: ${(props: IWithItems) =>
          props.haveItems ? 'none' : '2px solid black'};
      }
    }
  }
`

export const Button = styled(SemanticButton)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;

  @media (min-width: ${ResponsiveBreakpoints.Small}) {
    display: none;
    padding: 0 0 5px 0;
    padding-bottom: 5px;
  }
`

export const SecondUlContainer = styled.ul`
  list-style: none;
  margin: 0 0 0 0;
  background-color: white;
  display: ${(props: ISelected): string | null =>
    props.selected ? 'block' : 'none'};
  position: relative;
  z-index: 20;
  @media (min-width: ${ResponsiveBreakpoints.Small}) {
    position: absolute;
    background-color: white;
    z-index: ${zIndex.normal};
    left: 0;
    min-width: 150px;
    border: 1px solid #979797;
  }
`
export const SecondLiContainer = styled.li`
  font-weight: normal;
  margin: 0;
  cursor: pointer;
  &:first-child {
    border-top: 1px solid #e9e9e9;
  }
  &:hover {
    background-color: #f4f4f4;
  }

  > ${RouterBasicLink} {
    display: block;
    padding: 10px 0;
    width: 100%;
  }

  @media (min-width: ${ResponsiveBreakpoints.Small}) {
    > ${RouterBasicLink} {
      padding: 10px 20px;
    }
  }
`

import * as React from 'react'

interface IWindow extends Window {
  googleTranslateElementInit: () => void
  google: any // couldn't find something else..
}

class GoogleTranslate extends React.Component {
  private customWindow = null as IWindow
  private mutationObserver: MutationObserver

  componentWillUnmount() {
    if (this.mutationObserver) this.mutationObserver.disconnect()
  }

  componentDidMount() {
    this.customWindow = window as IWindow

    this.mutationObserver = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        const iframeGoogle = document.querySelector(
          '.goog-te-banner-frame'
        ) as HTMLIFrameElement

        // Check if translation is in progress <=> top google translation bar is visible
        if (iframeGoogle) {
          const { contentDocument, contentWindow } = iframeGoogle
          const innerDoc = contentDocument || contentWindow.document

          const translateCloseBtn = innerDoc.querySelector('.goog-close-link')
          if (translateCloseBtn) {
            translateCloseBtn.addEventListener('click', () => {
              this.customWindow.location.reload()
            })
          }
        }
      })
    })

    if (!this.customWindow.googleTranslateElementInit) {
      this.customWindow.googleTranslateElementInit = this.googleTranslateElementInit.bind(
        this
      )
    }

    const scriptLoaded = document.querySelector('#gtranslate-script')
    if (scriptLoaded) scriptLoaded.remove()

    const googleTranslateScript = document.createElement('script')
    googleTranslateScript.id = 'gtranslate-script'
    googleTranslateScript.type = 'text/javascript'
    googleTranslateScript.async = true
    googleTranslateScript.src =
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    ;(
      document.getElementsByTagName('head')[0] ||
      document.getElementsByTagName('body')[0]
    ).appendChild(googleTranslateScript)

    this.mutationObserver.observe(document.documentElement, {
      attributes: true,
    })
  }

  googleTranslateElementInit() {
    // Load script only if no translation is in progress
    const iframeGoogle = document.querySelector('.goog-te-banner-frame')

    if (this.customWindow && !iframeGoogle) {
      const gWidget = new this.customWindow.google.translate.TranslateElement(
        {
          pageLanguage: 'en',
          layout: this.customWindow.google.translate.TranslateElement
            .InlineLayout.SIMPLE,
          includedLanguages: 'cs,da,nl,fi,fr,de,hu,is,it,pl,pt,sm,sl,es,sv',
        },
        'google_translate_element'
      )
      return gWidget
    }
  }

  render() {
    return <div id="google_translate_element" />
  }
}

export default GoogleTranslate

import { properties } from '@awdis/components'
import * as React from 'react'
import styled from 'styled-components'
import { SectionProps } from '../Section'
import ImageSection, { EmptyImageSection } from './components/ImageSection'

const { ResponsiveBreakpoints } = properties

type Props = {
  imageRight: SectionProps
  imageLeft: SectionProps
}

export const MARGIN_BETWEEN_IMAGES = 15

const ImageImageWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: -15px -15px 15px;

  > ${ImageSection}, > ${EmptyImageSection} {
    margin: ${MARGIN_BETWEEN_IMAGES}px;
  }

  @media (max-width: ${ResponsiveBreakpoints.Small}) {
    flex-direction: column;

    > ${ImageSection}, > ${EmptyImageSection} {
      max-width: 450px;
    }

    > ${EmptyImageSection} {
      display: none;
    }
  }
`

const ImageImage = ({ imageRight, imageLeft }: Props) => (
  <ImageImageWrapper>
    {imageLeft ? <ImageSection {...imageLeft} fluid /> : <EmptyImageSection />}
    {imageRight ? (
      <ImageSection {...imageRight} fluid />
    ) : (
      <EmptyImageSection />
    )}
  </ImageImageWrapper>
)

export default ImageImage

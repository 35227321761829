import { properties } from '@awdis/components'
import styled, { css } from 'styled-components'
import { MARGIN_BETWEEN_IMAGES } from '../..'
import Section from '../../../Section'

const { MAIN_CONTAINER_SIDE_PADDING, MAIN_CONTAINER_WIDTH } = properties

const IMAGE_MAX_WIDTH =
  (MAIN_CONTAINER_WIDTH -
    2 * MAIN_CONTAINER_SIDE_PADDING -
    2 * MARGIN_BETWEEN_IMAGES) /
  2

const imageSectionStyle = css`
  height: 300px;
  max-width: ${IMAGE_MAX_WIDTH}px;
`

const ImageSection = styled(Section)`
  ${imageSectionStyle}
`

export const EmptyImageSection = styled.div.attrs({
  ariaHidden: true,
})`
  ${imageSectionStyle}
  width: 100%;
`

export default ImageSection

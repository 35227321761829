import { properties } from '@awdis/components'
import styled from 'styled-components'
import DistributorBlock from '../DistributorBlock'

const { ResponsiveBreakpoints } = properties

const DistributorsGrid = styled.ul`
  list-style: none;
  margin: -10px;
  padding: 25px 0;

  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: ${ResponsiveBreakpoints.Small}) {
    grid-template-columns: 1fr;
  }

  > li > ${DistributorBlock} {
    width: 100%;
  }
`

export default DistributorsGrid

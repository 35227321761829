import { ISizeGuide } from '@awdis/components'
import * as React from 'react'
import styled from 'styled-components'
import DataTable from '../DataTable'

type InternationalSizeGuideProps = {
  sizeGuides: Array<ISizeGuide>
}

const defaultProps = {}

const SizeGuideTable = styled(DataTable)`
  margin-bottom: 20px;
`

const InternationalSizeGuide = function InternationalSizeGuide({
  sizeGuides,
}: InternationalSizeGuideProps) {
  return (
    <>
      {sizeGuides.map((table: ISizeGuide) => (
        <SizeGuideTable key={table.title} isScrollable {...table} />
      ))}
    </>
  )
}

InternationalSizeGuide.defaultProps = defaultProps

export default InternationalSizeGuide

import { IFile, IProduct, ISizeGuide, Title } from '@awdis/components'
import { add, endOfDay, format, startOfDay, sub } from 'date-fns'
import enGB from 'date-fns/locale/en-GB'
import * as React from 'react'
import styled from 'styled-components'
import ContactRedirectItems from '../../components/ContactRedirectItems'
import { DistributorLocation } from '../../components/DistributorsMap'
import Layout from '../../components/Layout'
import { pageTitleStyle } from '../../components/Layout/components/TemplatePageStyle'
import Message from '../../components/Message'
import Seo from '../../components/Seo'
import TemplateComponent from '../../components/TemplateComponent'
import { IDistributorDetail } from '../../services/types/distributors'
import { Menu } from '../../services/types/menu'
import { IPageTemplate, TemplateType } from '../../services/types/pages'
import { Social } from '../../services/types/social'

type Props = {
  pageContext: {
    sections: IPageTemplate[]
    menu: Array<Menu>
    footer: Array<Menu>
    footerLogos: Array<{
      id: string
      url: string
    }>
    social: Social
    logo: string
    brandName: string
    brandDescription: string
    products: Array<IProduct>
    isNewIn: boolean
    resources: Array<IFile>
    sizeGuides: Array<ISizeGuide>
    distributorsDetails: Array<IDistributorDetail>
    page: {
      title: string
      url: string
    }
  }
}

const TemplateContainer = styled.div`
  h1,
  h2 {
    ${pageTitleStyle}
  }
`

const START_HOLIDAY_DATE = new Date(2021, 11, 24)
const END_HOLIDAY_DATE = new Date(2022, 0, 4)

export default class PageTemplate extends React.Component<Props> {
  render() {
    const {
      pageContext: {
        sections,
        menu,
        footer,
        footerLogos,
        social,
        logo,
        brandName,
        brandDescription,
        isNewIn,
        resources,
        sizeGuides,
        distributorsDetails,
        page: { title, url },
      },
    } = this.props

    const templateNodes =
      sections && sections.length
        ? sections.map((section: any) => {
            const { id, type, props } = section
            let extraProps = {}

            switch (type) {
              case TemplateType.SizeGuide: {
                extraProps = {
                  sizeGuides,
                }
                break
              }

              case TemplateType.ResourceCards: {
                extraProps = {
                  resources,
                }
                break
              }

              case TemplateType.DistributorAccordions: {
                extraProps = {
                  distributorsDetails,
                }
                break
              }

              case TemplateType.DistributorMap: {
                extraProps = {
                  distributorLocations: distributorsDetails
                    .filter(detail => !!detail.distributor.location)
                    .map(
                      (detail): DistributorLocation => ({
                        lat: detail.distributor.location.latitude,
                        lng: detail.distributor.location.longitude,
                        data: detail,
                      })
                    ),
                }
                break
              }

              default: {
                break
              }
            }

            return (
              <TemplateComponent
                key={id}
                id={id}
                type={type}
                {...props}
                {...extraProps}
              />
            )
          })
        : null

    const today = new Date()
    const isHolidaySeason =
      startOfDay(sub(START_HOLIDAY_DATE, { days: 14 })) <= today &&
      today <= endOfDay(add(END_HOLIDAY_DATE, { days: 1 }))

    const isHomePage = title === 'Home' && url === '/'
    const isBrochurePage = title === 'Brochure' && url === '/brochure'

    return (
      <Layout
        data={{
          menu,
          footer,
          footerLogos,
          social,
          logo,
          brandName,
          brandDescription,
          isNewIn,
        }}
      >
        <Seo
          title={title}
          description={brandDescription}
          type="website"
          pathname={url && url.replace('/', '')}
        />
        <TemplateContainer>
          {url === '/' && isHolidaySeason && (
            // End-of-year holiday message
            <Message as="div" type="info">
              <Title align="center">Season’s greetings</Title>
              <p>
                Our UK office will be closed from{' '}
                {format(START_HOLIDAY_DATE, 'MMMM do, yyyy', {
                  locale: enGB,
                })}{' '}
                to {format(END_HOLIDAY_DATE, 'MMMM do, yyyy', { locale: enGB })}{' '}
                to spend time with our families and friends over the holiday
                season. During this period, we will be unable to reply to your
                messages or phone enquiries. We’ll be back up to speed on{' '}
                {format(END_HOLIDAY_DATE, 'MMMM do', { locale: enGB })} and will
                take care of all outstanding queries.
              </p>
              <p>Happy holidays!</p>
            </Message>
          )}

          {Array.isArray(templateNodes) && templateNodes.length > 0 ? (
            isHomePage ? (
              <>
                {/*
                  for the home page, client requested to show the contact items
                  just below the carousel (first template element)
                */}
                {templateNodes[0]}

                <ContactRedirectItems
                  style={{
                    paddingTop: 18,
                    paddingBottom: 48,
                  }}
                />

                {templateNodes.slice(1)}
              </>
            ) : (
              templateNodes
            )
          ) : (
            <h1>No Data Found</h1>
          )}

          {isBrochurePage ? (
            <ContactRedirectItems
              style={{
                paddingTop: 18,
                paddingBottom: 48,
              }}
            />
          ) : null}
        </TemplateContainer>
      </Layout>
    )
  }
}

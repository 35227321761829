import * as React from 'react'
import Cell, { HeaderCell } from '../Cell'
import Row from '../Row'
import Table from '../Table'
import TableBody from '../TableBody'
import TableHead from '../TableHead'

type VerticalTableProps = {
  fields: string[][]
}

const defaultProps = {}

const VerticalTable = function VerticalTable({ fields }: VerticalTableProps) {
  const [headers, ...rows] = fields
  return (
    <Table>
      <TableHead>
        <Row>
          {headers.map((header, i) => (
            <HeaderCell key={i}>{header}</HeaderCell>
          ))}
        </Row>
      </TableHead>
      <TableBody>
        {rows.map((cells, we) => (
          <Row key={we}>
            {cells.map((col, index) => (
              <Cell key={index}>{col}</Cell>
            ))}
          </Row>
        ))}
      </TableBody>
    </Table>
  )
}

VerticalTable.defaultProps = defaultProps

export default VerticalTable

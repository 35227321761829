import { semanticLinkStyle } from '@awdis/components'
import { Link } from 'gatsby'
import * as React from 'react'
import styled from 'styled-components'

const SemanticRouterLink = styled(({ fluid, ...props }) => <Link {...props} />)`
  ${semanticLinkStyle}
`

export default SemanticRouterLink

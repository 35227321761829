import { Budicon, colours } from '@awdis/components'
import * as React from 'react'
import styled, { css } from 'styled-components'
import {
  IDistributor,
  IDistributorDetail,
} from '../../../../services/types/distributors'
import DistributorAddress from '../DistributorAddress'
import DistributorName from '../DistributorName'
import DistributorPhone from '../DistributorPhone'
import DistributorWebsite from '../DistributorWebsite'

const { BLACK, LIGHT_GREY, PALE_GREY } = colours

type DistributorBlockProps = {
  distributorDetail: IDistributorDetail
  className?: string
}

const defaultProps = {
  as: 'div',
}

const PinIcon = styled(Budicon).attrs({
  name: 'location-pin',
  size: 'medium',
})`
  display: block;
`

const DistributorBlockC = function DistributorBlockC({
  className,
  distributorDetail: {
    distributor: { name, url: defaultUrl, street, postcode, city, phone },
    url: brandUrl,
    urlLabel: brandUrlLabel,
  },
  ...props
}: DistributorBlockProps) {
  let As: React.ReactType = 'div'
  let extraProps = {}
  const url = brandUrl || defaultUrl
  const urlLabel = brandUrlLabel || url

  // If given a url, show as <a />
  if (url) {
    As = 'a'
    extraProps = {
      href: url,
      target: '_blank',
    }
  }

  return (
    <As className={className} {...extraProps} {...props}>
      <PinIcon />
      <DistributorName>{name}</DistributorName>
      {(street || postcode || city) && (
        <DistributorAddress {...{ street, postcode, city }} />
      )}
      {urlLabel && <DistributorWebsite>{urlLabel}</DistributorWebsite>}
      {phone && <DistributorPhone>{phone}</DistributorPhone>}
    </As>
  )
}

DistributorBlockC.defaultProps = defaultProps

const hoverStyle = css`
  background: ${LIGHT_GREY};
  border-color: ${PALE_GREY};
  transition: 0.25s background, 0.25s border;
`

const DistributorBlock = styled(DistributorBlockC)<DistributorBlockProps>`
  border: 1px solid transparent;
  border-radius: 3px;
  display: block;
  color: ${BLACK};
  padding: 10px 15px;
  text-decoration: none;
  transition: 0.2s background, 0.2s border;

  &:hover {
    ${({
      distributorDetail: {
        url,
        distributor: { url: defaultUrl },
      },
    }) => (!!(url || defaultUrl) ? hoverStyle : '')};
  }

  > ${PinIcon},
    > ${DistributorName},
    > ${DistributorAddress},
    > ${DistributorWebsite},
    > ${DistributorPhone} {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`

export default DistributorBlock

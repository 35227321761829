import styled from 'styled-components'
import { colours } from '@awdis/components'

const { LIGHT_GREY, BROWNISH_GREY, BLACK } = colours

const Caption = styled.caption`
  background: ${LIGHT_GREY};
  border: 1px solid ${BROWNISH_GREY};
  border-bottom: none;
  display: block;
  padding: 1.417rem;
  font-weight: 600;
  font-size: 1.167rem;
  color: ${BLACK};
  width: 100%;
  text-align: left;
`

export default Caption

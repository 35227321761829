import { createGlobalStyle } from 'styled-components'
import { properties } from '@awdis/components'

const { ResponsiveBreakpoints } = properties

const CookieBannerStyle = createGlobalStyle`
  .cookie-consent-banner {
    top: auto !important;
    bottom: 0;
    @media (max-width: ${ResponsiveBreakpoints.Medium}) {
      flex-direction: column;
    }
  }

  .cookie-consent-banner > .button {
    align-self: center;
    background-color: #fff;
    border: 1px solid #292929;
    color: #292929;
    cursor: pointer;
    flex: 0 0 auto;
    font-size: 1rem;
    font-style: normal;
    font-weight: normal;
    padding: 0.5em 1.5em;
    min-width: 110px;
    margin: 15px;
  }

  .cookie-consent-banner > .button:hover {
    color: #fff;
    background-color: #292929;
  }
`

export default CookieBannerStyle

import * as React from 'react'
import styled from 'styled-components'
import { Title } from '@awdis/components'

import HubspotNewsletterForm from '../../../HubspotNewsletterForm'

const NewsLetterWrapper = styled.div`
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
`

const NewsLetter = () => (
  <NewsLetterWrapper>
    <Title marginBottom="20px" align="center" size="small">
      SIGN UP TO OUR NEWSLETTER
    </Title>

    <HubspotNewsletterForm />
  </NewsLetterWrapper>
)

export default NewsLetter

import {
  GoogleMap,
  InfoBox,
  Marker,
  MarkerClusterer,
  useJsApiLoader,
} from '@react-google-maps/api'
import * as React from 'react'

import Dropdown from '../Dropdown'
import { IDistributorDetail } from '../../services/types/distributors'
import {
  getAddressLocation,
  getClientIP,
  getCountryCode,
} from '../../services/api/location'

export type DistributorLocation = {
  lat: number
  lng: number
  data: IDistributorDetail
}

export type DistributorsMapProps = {
  distributorLocations: DistributorLocation[]
}

const DistributorsMap: React.FC<DistributorsMapProps> = ({
  distributorLocations,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'distributors-google-map',
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
  })

  const [map, setMap] = React.useState<google.maps.Map | null>(null)
  const [countryFilter, setCountryFilter] = React.useState<string | null>(null)

  const infoBoxRef = React.useRef<HTMLDivElement>(null)

  const [selectedDistributorId, setSelectedDistributorId] = React.useState<
    string | null
  >(null)

  const filteredDistributorLocations = countryFilter
    ? distributorLocations.filter(
        location => location.data.distributor.country === countryFilter
      )
    : distributorLocations

  React.useEffect(() => {
    async function getUserLocation() {
      let userLocation: { lat: number; lng: number }
      try {
        const userIp = await getClientIP()
        const userCountryCode = await getCountryCode(userIp)
        userLocation = await getAddressLocation(userCountryCode)
      } catch (error) {
        console.error(error)
        const defaultCountry = process.env.GATSBY_BRAND.includes('US')
          ? 'united state'
          : 'europe'
        userLocation = await getAddressLocation(defaultCountry)
      }

      return userLocation
    }

    async function centreMapOnMarkers() {
      if (!map) {
        return
      }

      // Centre on user's location when no country filter
      const location = await (countryFilter
        ? getAddressLocation(countryFilter)
        : getUserLocation())
      map.setCenter(location)

      // go through each marker location, and extend map bounds
      // to fit all markers within it
      const bounds = new google.maps.LatLngBounds()
      filteredDistributorLocations.forEach(({ lat, lng }) => {
        bounds.extend(new google.maps.LatLng({ lat, lng }))
      })
      map.fitBounds(bounds)

      const zoom = map.getZoom()
      if (zoom > 8) {
        map.setZoom(8)
      }
    }

    centreMapOnMarkers()
  }, [map, countryFilter])

  const onLoad = React.useCallback((map: google.maps.Map) => {
    setMap(map)
  }, [])

  const onUnmount = React.useCallback((map: google.maps.Map) => {
    setMap(null)
  }, [])

  if (
    !isLoaded ||
    !Array.isArray(filteredDistributorLocations) ||
    filteredDistributorLocations.length < 1
  ) {
    return null
  }

  const countriesSet = distributorLocations.map(
    location => location.data.distributor.country
  )
  // Remove duplicate values
  const countriesOptions = countriesSet
    .filter((country, index) => countriesSet.indexOf(country) === index)
    .sort()

  return (
    <GoogleMap
      mapContainerStyle={{
        width: '100%',
        height: '550px',
        position: 'relative',
      }}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        disableDefaultUI: true,
        mapId: '18856e60517fe27b',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '15px',
          left: '20px',
          minWidth: '30%',
        }}
      >
        <Dropdown
          name="country"
          options={countriesOptions}
          onChange={({ target: { value } }) => setCountryFilter(value || null)}
          isClearable
          value={countryFilter}
        />
      </div>

      <MarkerClusterer
        minimumClusterSize={2}
        gridSize={30}
        styles={[
          {
            url: '/map-clusterer.svg',
            height: 102,
            width: 102,
            textSize: 20,
            fontWeight: 'bold',
            textColor: '#ffffff',
          },
        ]}
      >
        {clusterer => (
          <React.Fragment>
            {filteredDistributorLocations.map(
              ({
                lat,
                lng,
                data: {
                  id,
                  urlLabel,
                  url: brandURL,
                  distributor,
                  distributor: { url: defaultURL },
                },
              }) => (
                <React.Fragment key={id}>
                  <Marker
                    clusterer={clusterer}
                    icon={{
                      url: '/map-marker.svg',
                      anchor: new google.maps.Point(9, 20),
                      scaledSize: new google.maps.Size(32, 40),
                    }}
                    position={{
                      lat,
                      lng,
                    }}
                    onClick={e => {
                      if (distributor.id === selectedDistributorId) {
                        setSelectedDistributorId(null)
                      } else {
                        setSelectedDistributorId(distributor.id)
                      }
                    }}
                    onMouseOver={e => setSelectedDistributorId(distributor.id)}
                    onMouseOut={e => setSelectedDistributorId(null)}
                  />

                  <InfoBox
                    position={
                      new google.maps.LatLng({
                        lat,
                        lng,
                      })
                    }
                    options={{
                      pixelOffset: new google.maps.Size(-108, 20),
                      visible: selectedDistributorId === distributor.id,
                      closeBoxURL: '', // to make close button hidden
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: '#fff',
                        padding: 32,
                        border: '1px solid #D0D5DD',
                        borderRadius: 8,
                        width: 216,
                      }}
                      ref={infoBoxRef}
                      onMouseOver={e =>
                        setSelectedDistributorId(distributor.id)
                      }
                      onMouseOut={e => setSelectedDistributorId(null)}
                    >
                      <p
                        style={{
                          fontSize: '18px',
                          lineHeight: '21px',
                          fontWeight: 700,
                          color: '#15232C',
                          marginBottom: '16px',
                        }}
                      >
                        {distributor.name}
                      </p>
                      {brandURL || defaultURL ? (
                        <div
                          style={{
                            marginBottom: '16px',
                          }}
                        >
                          <a
                            href={brandURL || defaultURL}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              fontSize: '16px',
                              lineHeight: '19px',
                              fontWeight: 400,
                              color: '#15232C',
                            }}
                          >
                            {urlLabel || brandURL || defaultURL}
                          </a>
                        </div>
                      ) : null}
                      {distributor.phone ? (
                        <div
                          style={{
                            marginBottom: '16px',
                          }}
                        >
                          <a
                            href={`tel:${distributor.phone}`}
                            style={{
                              fontSize: '16px',
                              lineHeight: '19px',
                              fontWeight: 400,
                              color: '#15232C',
                            }}
                          >
                            {distributor.phone}
                          </a>
                        </div>
                      ) : null}
                      <div
                        style={{
                          fontSize: '16px',
                          lineHeight: '19px',
                          fontWeight: 400,
                          color: '#15232C',
                        }}
                      >
                        <p
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          {distributor.street}
                        </p>
                        <p
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          {distributor.city}
                        </p>
                        <p
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          {distributor.postcode}
                        </p>
                        <p
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          {distributor.country}
                        </p>
                      </div>
                    </div>
                  </InfoBox>
                </React.Fragment>
              )
            )}
          </React.Fragment>
        )}
      </MarkerClusterer>
    </GoogleMap>
  )
}

export default DistributorsMap

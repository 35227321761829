import styled from 'styled-components'
import TextContainer from '../../../TextContainer'
import { colours } from '@awdis/components'

type HeroTextBlockProps = {
  backgroundColour?: string
}

const HeroTextBlock = styled(TextContainer).attrs<HeroTextBlockProps>({
  alignItems: 'center',
  backgroundColour: ({ backgroundColour = colours.LIGHT_GREY }) =>
    backgroundColour,
  contentPosition: 'centre',
})`
  padding: 2rem;
  margin-bottom: 0;
  text-align: left;
`

export default HeroTextBlock

import { navigate } from 'gatsby'
import * as React from 'react'
import { Items, Menu } from '../../../../../../../services/types/menu'
import { RouterBasicLink } from '../../../../../../BasicLink'
import {
  Button,
  FirstLiContainer,
  LinkArrowWrapper,
  SecondUlContainer,
  ArrowIcon,
} from '../styles'
import ItemList from './ItemList'

type State = {
  selected: string
}

type Props = {
  menu: Array<Menu>
  isNewIn?: boolean
}

class MenuItems extends React.Component<Props, State> {
  state = {
    selected: '',
  }

  selectList = (e: any, url: string, items: Array<Items>): void => {
    if (items.length === 0 && url) {
      navigate(url)
    }
    const { id } = e.target
    this.setState(({ selected }) => {
      return {
        selected: selected === id ? '' : id,
      }
    })
  }

  render() {
    const { menu, isNewIn } = this.props
    const { selected } = this.state
    return (
      <>
        {menu.map((menuItem, index) => {
          if (menuItem) {
            // Do not render new in menu, if there is no new in product available
            if (menuItem.title.toLocaleLowerCase() === 'new in' && !isNewIn) {
              return
            }

            return (
              <FirstLiContainer
                key={index}
                selected={selected === menuItem.title}
              >
                <LinkArrowWrapper
                  selected={selected === menuItem.title}
                  haveItems={menuItem.items && menuItem.items.length > 1}
                >
                  <RouterBasicLink
                    to={menuItem.url}
                    activeStyle={{
                      fontSize: '1.125rem',
                      fontWeight: 700,
                      borderBottom: '2px solid black',
                    }}
                  >
                    {menuItem.title}
                  </RouterBasicLink>
                  {menuItem.items && menuItem.items.length > 0 ? (
                    <ArrowIcon
                      name={
                        selected === menuItem.title
                          ? 'arrow-bottom'
                          : 'arrow-right'
                      }
                      size="medium"
                    />
                  ) : (
                    <ArrowIcon name="arrow-right" size="medium" />
                  )}
                </LinkArrowWrapper>
                <Button
                  onClick={e =>
                    this.selectList(e, menuItem.url, menuItem.items)
                  }
                  id={menuItem.title}
                />
                {menuItem.items && menuItem.items.length > 1 && (
                  <SecondUlContainer selected={selected === menuItem.title}>
                    <ItemList items={menuItem.items} />
                  </SecondUlContainer>
                )}
              </FirstLiContainer>
            )
          }
        })}
      </>
    )
  }
}

export default MenuItems

import * as React from 'react'
import styled from 'styled-components'
import { Accordion } from '@awdis/components'
import Html from '../Html'

export interface IAccordionTemplate {
  title: string
  html: string
}

type Props = IAccordionTemplate

const StyledAccordion = styled(Accordion)`
  margin-bottom: 20px;
`

const AccordionTemplate = ({ title, html, ...props }: Props) => {
  return (
    <StyledAccordion title={title} iconType="arrow" {...props}>
      <Html html={html} />
    </StyledAccordion>
  )
}

export default AccordionTemplate

import { colours } from '@awdis/components'
import styled, { css } from 'styled-components'
import Caption from '../Caption'
import Cell, { HeaderCell } from '../Cell'
import Row from '../Row'
import TableBody from '../TableBody'
import TableHead from '../TableHead'

const horizontalTableStyle = css`
  /* Display the thead and tbody inline */
  display: flex;
  border-top: none;

  > ${TableHead} {
    border-right: 1px solid ${colours.BROWNISH_GREY};
    flex: 0 0 auto;
  }

  > ${TableBody} {
    flex: 1 1 100%;
    overflow: scroll;
  }

  /* Hide bottom border on mobile to avoid double border */
  ${Row}&:last-child > ${HeaderCell},
  ${Row}&:last-child > ${Cell} {
    border-bottom: none;
  }

  ${Row} > ${Cell}:last-child {
    /* Weird fix for small tables */
    width: 58%;
  }
`

type TableProps = {
  horizontal?: boolean
}

const Table = styled.table<TableProps>`
  border: 1px solid ${colours.BROWNISH_GREY};
  border-collapse: collapse;
  table-layout: fixed;

  /* If there's a title displayed, remove border at the top */
  > ${Caption} + ${TableBody} {
    border-top: none;
  }

  ${({ horizontal = false }) => (horizontal ? horizontalTableStyle : '')};
`

export default Table

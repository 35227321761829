import * as React from 'react'
import SimpleLineIcon from 'react-simple-line-icons'
import { Social } from '../../../../../../services/types/social'
import styled from 'styled-components'
import { colours } from '@awdis/components'

type SocialLinkProps = {
  socialName: string
  href: string
  className?: string
}

const defaultProps = {}

const socialIcons: Social = {
  instagram: 'social-instagram',
  twitter: 'social-twitter',
  facebook: 'social-facebook',
  linkedin: 'social-linkedin',
}

const SocialLinkComp = function SocialLinkComp({
  socialName,
  ...props
}: SocialLinkProps) {
  return (
    <a target="_blank" {...props}>
      <SimpleLineIcon color={colours.BLACK} name={socialIcons[socialName]} />
    </a>
  )
}

SocialLinkComp.defaultProps = defaultProps

const SocialLink = styled(SocialLinkComp)`
  &:hover > .icon-social-instagram,
  &:hover > .icon-social-twitter,
  &:hover > .icon-social-facebook,
  &:hover > .icon-social-linkedin {
    font-weight: 800;
  }
`

export default SocialLink

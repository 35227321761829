import styled from 'styled-components'
import { colours } from '@awdis/components'

type Props = {
  src?: string
}

export const CardMedia = styled.img`
  box-shadow: ${({ src }: Props) =>
    src ? `2px 2px 4px 0 ${colours.INNER_SHADOW}` : ''};
  object-fit: cover;
  margin-bottom: 0;
  width: 80px;
  opacity: ${({ src }: Props) =>
    src ? 1 : 0}; // hide image broken icon on error
`

import styled from 'styled-components'
import { properties } from '@awdis/components'

const { zIndex, ResponsiveBreakpoints } = properties

export const Logo = styled.img`
  max-width: 300px;
  @media (max-width: ${ResponsiveBreakpoints.Small}) {
    max-width: 200px;
  }
`

export const RightHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  position: relative;
  z-index: ${zIndex.medium};
  margin: 10px 0px;
  @media (min-width: ${ResponsiveBreakpoints.Small}) {
    margin: 0 auto;
    max-width: 1180px;
    padding: 0px 15px 20px;
  }
`

export const HeaderContainer = styled.div`
  position: relative;
  z-index: ${zIndex.normal};
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  border-bottom: 1px solid #cfcfcf;

  .right {
    margin-right: 20px;
  }

  .left {
    margin-left: 20px;
  }

  @media (min-width: ${ResponsiveBreakpoints.Small}) {
    border-bottom: 0;

    > button {
      display: none;
    }
  }
`
export const GoogleTranslateContainer = styled.div`
  width: 163.5px;
  height: 36.3px;
  margin-bottom: 20px;
  @media (min-width: ${ResponsiveBreakpoints.Small}) {
    .goog-te-gadget-simple {
      padding: 6px 0 7px 6px;
      border: 1px solid #5d5d5d !important;
      font-family: Suisse;
    }

    .goog-te-gadget-simple .goog-te-menu-value span:last-child {
      color: #5d5d5d !important;
    }

    .goog-te-gadget-icon {
      margin: 0;
    }
  }
`
export const ForCenterTheLogo = styled.div`
  display: none;
  @media (min-width: ${ResponsiveBreakpoints.Small}) {
    display: block;
    width: 254px;
  }
`

import * as React from 'react'
import styled from 'styled-components'

const region = process.env.GATSBY_HUBSPOT_REGION
const portalId = process.env.GATSBY_HUBSPOT_PORTAL_ID
const formId = process.env.GATSBY_HUBSPOT_NEWSLETTER_FORM_ID

const Container = styled.div`
  #hubspot-newsletter-form {
    > form {
      display: flex;
      height: 42px;
      border: solid 1px #5d5d5d;
      background-color: white;

      > .field {
        height: 100%;

        > label {
          display: none;
        }

        > .input {
          height: 100%;

          > input {
            height: 100%;
            outline: none;
            border: none;
            font-size: 0.9375rem;
            padding: 0 12px;
          }
        }

        > .hs-error-msgs {
          display: none !important;
        }
      }

      > .hs-submit {
        height: 100%;

        > .actions {
          height: 100%;

          > input[type='submit'] {
            height: 100%;
            border: none;
            font-size: 1rem;
            background-color: #5d5d5d;
            color: white;
            padding: 0 24px;
            font-weight: 500;
            cursor: pointer;

            &:hover,
            &:focus {
              background-color: #4a4a4a;
            }
          }
        }
      }

      > .hs_error_rollup {
        display: none;
      }
    }

    > .submitted-message {
      padding: 1.5rem;
      color: black;
    }
  }
`

const HubspotNewsletterForm: React.FC = () => {
  const createHubspotForm = () => {
    // @ts-ignore
    if (window.hbspt && region && portalId && formId) {
      // @ts-ignore
      window.hbspt.forms.create({
        region,
        portalId,
        formId,
        target: '#hubspot-newsletter-form',
      })
    }
  }

  React.useEffect(() => {
    const script = document.createElement('script')
    script.src = '//js.hsforms.net/forms/v2.js'
    document.body.appendChild(script)

    script.addEventListener('load', createHubspotForm)

    return () => {
      script.removeEventListener('load', createHubspotForm)
    }
  }, [])

  if (!region || !portalId || !formId) {
    return null
  }

  return (
    <Container>
      <div id="hubspot-newsletter-form" />
    </Container>
  )
}

export default HubspotNewsletterForm

import styled from 'styled-components'
import { colours } from '@awdis/components'

const Cell = styled.td`
  border-bottom: 1px solid ${colours.BROWNISH_GREY};
  font-weight: 500;
  font-size: 1.083rem;
  padding: 10px;
  text-align: center;
`

export const HeaderCell = styled(Cell).attrs({
  as: 'th',
})``

export default Cell

import 'focus-visible'
import { withPrefix } from 'gatsby'
import * as React from 'react'
import Helmet from 'react-helmet'
import { Menu } from '../../services/types/menu'
import { Social } from '../../services/types/social'
import FontDefinition from './components/FontDefinition'
import Footer from './components/Footer'
import Header from './components/Header'
import MainContainer from './components/MainContainer'
import NewsLetter from './components/NewsLetter'
import TemplatePageStyle from './components/TemplatePageStyle'
import './layout.css'
import CookieBanner from './components/CookieBanner'

import '../../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css'

export type LayoutData = {
  menu: Array<Menu>
  footer: Array<Menu>
  footerLogos: Array<{
    id: string
    url: string
    alt?: string
  }>
  social: Social
  brandName: string
  brandDescription: string
  isNewIn?: boolean
  logo?: string
}

type Props = {
  data: LayoutData
  children: React.ReactNode
}

const defaultProps = {
  data: {
    menu: [] as Array<Menu>,
    footer: [] as Array<Menu>,
    social: null as Social,
    brandName: '',
    logo: '',
  },
}

const Layout = ({
  children,
  data: { menu, footer, footerLogos, social, logo, brandName, isNewIn },
}: Props) => (
  <>
    <Helmet>
      <link
        type="text/css"
        rel="stylesheet"
        href={withPrefix('/budicon/style.css')}
      />
      <link
        type="text/css"
        rel="stylesheet"
        href={withPrefix('/budicon/style-solid.css')}
      />
      <script src="https://js.createsend1.com/javascript/copypastesubscribeformlogic.js" />

      {brandName === 'Just Hoods' && (
        <script // <!-- Start of HubSpot Embed Code -->
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src="//js.hs-scripts.com/8156310.js"
        /> // <!-- End of HubSpot Embed Code -->
      )}
    </Helmet>

    <FontDefinition />
    <TemplatePageStyle />

    <Header
      isNewIn={isNewIn}
      siteTitle={brandName}
      brandName={brandName}
      logo={logo}
      menu={menu}
    />

    <MainContainer>{children}</MainContainer>

    <NewsLetter />

    <Footer menu={footer} social={social} logos={footerLogos} />

    {/* Need to be fix */}
    <CookieBanner />
  </>
)

Layout.defaultProps = defaultProps

export default Layout

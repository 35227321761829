import { IFile } from '@awdis/components'
import * as React from 'react'
import HtmlCards from '../HtmlCards'

type ResourceCardsProps = {
  resources: Array<IFile>
  html: string
  key: string
}

const defaultProps = {}

const ResourceCards = function ResourceCards({
  resources = [],
  ...props
}: ResourceCardsProps) {
  const cards = resources.map(({ name, url, preview }) => ({
    title: name,
    image: preview,
    btnLink: url,
    btnText: 'Download',
    text: '',
  }))
  return <HtmlCards cards={cards} {...props} />
}

ResourceCards.defaultProps = defaultProps

export default ResourceCards

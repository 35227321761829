import * as React from 'react'
import Cell, { HeaderCell } from '../Cell'
import Row from '../Row'
import Table from '../Table'
import TableBody from '../TableBody'
import TableHead from '../TableHead'

type HorizontalTableProps = {
  fields: string[][]
}

const defaultProps = {}

const HorizontalTable = function HorizontalTable({
  fields,
}: HorizontalTableProps) {
  const [headers, ...rows] = fields
  return (
    <Table horizontal>
      <TableHead>
        {headers.map((field, i) => (
          <Row key={i}>
            <HeaderCell>{field}</HeaderCell>
          </Row>
        ))}
      </TableHead>
      <TableBody>
        {/*
         * For each cell in the header, map through the rows to grab the cell
         * corresponding to this header, as we are reversing the axes.
         */}
        {headers.map((header, index) => (
          <Row key={header}>
            {rows.map((row, rowIndex) => (
              <Cell key={rowIndex}>{row[index]}</Cell>
            ))}
          </Row>
        ))}
      </TableBody>
    </Table>
  )
}

HorizontalTable.defaultProps = defaultProps

export default HorizontalTable

import { colours, config, properties } from '@awdis/components'
import * as React from 'react'
import styled from 'styled-components'
import { Menu } from '../../../../services/types/menu'
import { Social } from '../../../../services/types/social'
import FooterMenu, {
  MenuTitle,
  MenuWrapper,
  SubMenuTitleExternal,
} from './components/FooterMenu'
import FooterMenuSocial from './components/FooterMenuSocial'

const { MAIN_CONTAINER_WIDTH, ResponsiveBreakpoints } = properties

export interface IMenu {
  title: string
  items: Array<IMenuItem>
}

export interface IMenuItem {
  id: string
  title: string
  url: string
  params?: JSON
}

export interface IMenuSocial {
  type: string
  url: string
}

type Props = {
  menu: Array<Menu>
  social: Social
  logos: Array<{
    id: string
    url: string
    alt?: string
  }>
}

const FooterWrapper = styled.div`
  min-height: 222px;
  padding-top: 30px;
  background-color: #f0f1f3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const FooterContainer = styled.div`
  max-width: ${MAIN_CONTAINER_WIDTH}px;
  width: 100%;
  padding: 0px 1.0875rem 2.45rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  @media (max-width: ${ResponsiveBreakpoints.Small}) {
    flex-direction: column;
  }
`

const FooterColumn = styled.div`
  @media (max-width: ${ResponsiveBreakpoints.Small}) {
    margin-bottom: 20px;
  }
`

const FooterSocialColumn = styled(FooterColumn)`
  @media (max-width: ${ResponsiveBreakpoints.Small}) {
    order: -1;
  }
`

const FooterLogosColumn = styled(FooterColumn)`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: -10px;

  > img {
    display: block;
    margin: 10px;
  }

  @media (max-width: ${ResponsiveBreakpoints.Small}) {
    flex-wrap: wrap;
    justify-content: center;
  }
`

const Small = styled.small`
  display: block;
  font-size: 0.8333rem;
  font-weight: 300;
  color: ${colours.GREY};
  text-align: center;
  padding-bottom: 20px;
`

const CustomLink = styled.a`
  text-decoration: none;

  :hover {
    color: black !important;
    text-decoration: underline;
    font-weight: 500;
  }

  :visited {
    color: ${colours.GREY};
  }
`

const getCurrentYear = () => {
  const d = new Date()
  return d.getFullYear()
}

const Footer = ({ menu, social, logos }: Props) => (
  <FooterWrapper>
    <FooterContainer>
      {menu.map((menuItem: Menu) => (
        <FooterColumn key={menuItem.title}>
          <FooterMenu {...menuItem} />
        </FooterColumn>
      ))}
      {social && (
        <FooterSocialColumn>
          <FooterMenuSocial {...{ social }} />
          <MenuWrapper>
            <MenuTitle>Call us for more information</MenuTitle>
            <SubMenuTitleExternal href={`tel:${config.contact.contactNumber.tel}`}>
              {config.contact.contactNumber.readable}
            </SubMenuTitleExternal>
          </MenuWrapper>
        </FooterSocialColumn>
      )}
      {logos && logos.length > 0 && (
        <FooterLogosColumn>
          {logos.map(logo => (
            <img key={logo.id} height="125" src={logo.url} alt={logo.alt} />
          ))}
        </FooterLogosColumn>
      )}
    </FooterContainer>
    <Small>
      © {getCurrentYear()} AWDis | AWDis Ltd, Registered in England & Wales,
      Company number 08771810 | Registered office: 35 Beaufort Court, Admirals
      Way South Quay Waterside, London, E14 9XL
    </Small>
    <Small>
      <CustomLink href="https://gearedapp.co.uk/" target="_blank">
        Website by GearedApp
      </CustomLink>
    </Small>
  </FooterWrapper>
)

export default Footer

import * as React from 'react'
import { Items } from '../../../../../../../services/types/menu'
import { RouterBasicLink } from '../../../../../../BasicLink'
import { SecondLiContainer } from '../styles'

type ItemListProps = {
  items: Array<Items>
}

const ItemList = ({ items }: ItemListProps) => {
  return (
    <>
      {items.map(item => (
        <SecondLiContainer key={item.id}>
          <RouterBasicLink to={item.url}>{item.title}</RouterBasicLink>
        </SecondLiContainer>
      ))}
    </>
  )
}

export default ItemList

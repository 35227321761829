import { createGlobalStyle, css } from 'styled-components'

export const pageTitleStyle = css`
  font-weight: 600;
  margin-bottom: 8px;
  text-transform: uppercase;
`

const TemplatePageStyle = createGlobalStyle`
  .page-title {
    ${pageTitleStyle}
  }
`

export default TemplatePageStyle

import * as React from 'react'
import CookieConsent from 'react-cookie-consent'
import styled from 'styled-components'
import CookieBannerStyle from './CookieBannerStyle'

const Title = styled.h1`
  color: black;
  font-size: 1.12rem;
  font-weight: 500;
`

const TextContainer = styled.div`
  font-size: 0.94rem;
  color: #292929;
  font-weight: lighter;
`

const ExternalLink = styled.a`
  font-style: italic;
  text-decoration: none;
  color: #292929;
`
// This component handle itself the one time banner behaviour once you click on `I agree`
// On the other hand I couldn't use easily styled component here I had to use a css file and react style
// We can either find a different library or do it from scratch.
const CookieBanner = () => (
  <>
    <CookieBannerStyle />
    <CookieConsent
      location="top"
      debug={false}
      buttonText="I agree"
      disableButtonStyles
      containerClasses="cookie-consent-banner"
      buttonClasses="button"
      style={{
        justifyContent: 'center',
        background: 'white',
        boxShadow: '0 -3px 4px 1px rgba(120, 120, 120, 0.59)',
      }}
      contentStyle={{
        maxWidth: '900px',
        flex: '1 0 auto',
      }}
    >
      <Title>Cookie Consent</Title>
      <TextContainer>
        This website uses cookies to enhance the user experience. By clicking 'I
        Agree' you consent to these cookies.
      </TextContainer>
      <TextContainer>
        Please see our privacy policy{' '}
        <ExternalLink href="/privacy-policy" target="_blank">
          here
        </ExternalLink>
      </TextContainer>
    </CookieConsent>
  </>
)

export default CookieBanner

import * as React from 'react'
import LinkButton from '../LinkButton'
import { Title } from '@awdis/components'
import CardBody from './components/CardBody'
import CardContainer from './components/CardContainer'
import { CardMedia } from './components/CardMedia'
import CardText from './components/CardText'

export interface ICard {
  image: string
  title: string
  text: string
  btnText: string
  btnLink: string
}

export type CardProps = {
  fill?: boolean
} & ICard

const Card = ({
  image,
  title,
  text,
  btnText,
  btnLink,
  ...props
}: CardProps) => {
  return (
    <CardContainer {...props}>
      <CardMedia src={image} />
      <CardBody>
        <Title as="h3" size="medium" alignSelf="flex-start" textCase="normal">
          {title}
        </Title>
        <CardText>{text}</CardText>
        <LinkButton href={btnLink} target="_blank" relaxed>
          {btnText}
        </LinkButton>
      </CardBody>
    </CardContainer>
  )
}

export default Card

import * as React from 'react'
import { createSelector } from 'reselect'
import styled from 'styled-components'
import { IDistributorDetail } from '../../services/types/distributors'
import { Accordion } from '@awdis/components'
import Html from '../Html'
import Panel from '../Panel'
import DistributorBlock from './components/DistributorBlock'
import DistributorsGrid from './components/DistributorsGrid'

export type DistributorAccordionsProps = {
  distributorsDetails: Array<IDistributorDetail>
  html?: string
}

export type DistributorAccordionsState = {}

const DistributorAccordionsContainers = styled.section`
  display: block !important;
`

class DistributorAccordions extends React.Component<
  DistributorAccordionsProps,
  DistributorAccordionsState
> {
  static defaultProps = {
    distributors: [],
  }

  selectDistributorsGroupedByCountry = createSelector(
    (_: DistributorAccordionsState, props: DistributorAccordionsProps) =>
      props.distributorsDetails,
    distributorsDetails => {
      const distributorsGroupedByCountry = distributorsDetails.reduce(
        (groups, distributorDetail) => {
          const {
            distributor: { country },
          } = distributorDetail
          const { [country]: countryGroup = [] } = groups
          return {
            ...groups,
            [country]: countryGroup.concat(distributorDetail),
          }
        },
        {} as { [country: string]: Array<IDistributorDetail> }
      )

      return distributorsGroupedByCountry
    }
  )

  render() {
    const { html } = this.props
    const distributorsDetailsGroupedByCountry = this.selectDistributorsGroupedByCountry(
      this.state,
      this.props
    )
    const countries = Object.keys(distributorsDetailsGroupedByCountry).sort()
    return (
      <Panel>
        {html && (
          <section>
            <Html html={html} />
          </section>
        )}
        <DistributorAccordionsContainers>
          {countries.map(country => (
            <Accordion key={country} title={country}>
              <DistributorsGrid>
                {distributorsDetailsGroupedByCountry[country].map(
                  distributorDetail => (
                    <li key={distributorDetail.id}>
                      <DistributorBlock
                        key={distributorDetail.id}
                        distributorDetail={distributorDetail}
                      />
                    </li>
                  )
                )}
              </DistributorsGrid>
            </Accordion>
          ))}
        </DistributorAccordionsContainers>
      </Panel>
    )
  }
}

export default DistributorAccordions

import { Carousel, SemanticLink } from '@awdis/components'
import * as React from 'react'

const TemplateCarousel = props => {
  return (
    <Carousel {...props} routerLinkComp={SemanticLink} hrefPropName="href" />
  )
}

export default TemplateCarousel

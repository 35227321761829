import * as React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'
import { Menu } from '../../../../../../services/types/menu'
import { IMenuItem } from '../..'

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;

  .goog-te-gadget-simple {
    padding: 6px 0 7px 6px;
    border: 1px solid #5d5d5d !important;
    font-family: Suisse;
  }

  .goog-te-gadget-simple .goog-te-menu-value span:last-child {
    color: #5d5d5d !important;
  }

  .goog-te-gadget-icon {
    margin: 0;
  }
`
export const MenuTitle = styled.span`
  font-family: Suisse;
  font-size: 0.9rem;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2f2f2f;
  margin-bottom: 18px;
`

const MenuStyle = css`
  font-family: Suisse;
  font-size: 1rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d6d;
  margin-bottom: 8px;

  text-decoration: none;
`

const SubMenuTitle = styled(Link)`
  ${MenuStyle}
`
export const SubMenuTitleExternal = styled.a`
  ${MenuStyle}
`

const FooterMenu = ({ title, items }: Menu) => (
  <MenuWrapper>
    <MenuTitle>{title}</MenuTitle>
    {items.map((menuItem: IMenuItem) => (
      <>
        {menuItem.url &&
        ['/privacy-policy', '/awdis-brands'].includes(
          menuItem.url.toLocaleLowerCase()
        ) ? (
          <SubMenuTitleExternal
            href={menuItem.url}
            target="_blank"
            key={menuItem.id}
          >
            {menuItem.title}
          </SubMenuTitleExternal>
        ) : (
          <SubMenuTitle
            to={menuItem.url || '/'}
            key={menuItem.id}
            activeStyle={{
              color: 'black',
              fontWeight: 'bold',
            }}
          >
            {menuItem.title}
          </SubMenuTitle>
        )}
      </>
    ))}
  </MenuWrapper>
)

export default FooterMenu

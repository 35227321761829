import { properties } from '@awdis/components'
import throttle from 'lodash.throttle'
import * as React from 'react'
import Caption from './components/Caption'
import HorizontalTable from './components/HorizontalTable'
import VerticalTable from './components/VerticalTable'

const { ResponsiveBreakpoints } = properties

type DataTableProps = {
  fields: Array<Array<string>>
  className?: string
  title?: string
  isScrollable?: boolean
}

export interface IGuideTableState {
  isMobile: boolean
}

class DataTable extends React.Component<DataTableProps, IGuideTableState> {
  static defaultProps = {
    isScrollable: false,
  }

  state: IGuideTableState = { isMobile: false }

  componentDidMount() {
    if (window) {
      window.addEventListener('resize', this.handleResizeWindow)
    }

    this.handleResizeWindow()
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener('resize', this.handleResizeWindow)
    }
  }

  handleResizeWindow = throttle(() => {
    const { isScrollable } = this.props
    if (window && isScrollable) {
      const parsedBreakPoint = parseInt(ResponsiveBreakpoints.Small, 10)
      const { innerWidth } = window

      let isMobile = false
      if (innerWidth <= parsedBreakPoint) {
        isMobile = true
      }

      this.setState({ isMobile })
    }
  }, 1000)

  render() {
    const { title, fields, className, isScrollable } = this.props
    const { isMobile } = this.state

    return (
      <div className={className}>
        {title && <Caption>{title}</Caption>}
        {isScrollable && isMobile ? (
          // Mobile table (display values horizontally)
          <HorizontalTable fields={fields} />
        ) : (
          // Desktop table
          <VerticalTable fields={fields} />
        )}
      </div>
    )
  }
}

export default DataTable

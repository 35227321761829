import { colours, properties } from '@awdis/components'
import styled from 'styled-components'

const { zIndex, ResponsiveBreakpoints } = properties

const { LIGHT_GREY, GREY, NEW_LIGHT_GREY } = colours

type ItemProps = {
  isActive?: boolean
  isSelected?: boolean
  noborder?: boolean
}
type SearchBarContainerProps = {
  noborderbot?: boolean
}

const Menu = styled.div`
  position: absolute;
  background: white;
  width: 100%;
  max-height: 20rem;
  overflow-y: auto;
  overflow-x: hidden;
  border-top-width: 0;
  outline: 0;
  transition: opacity 0.1s ease;
  border: 1px solid #5d5d5d;
`

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 8px 5px;
  background: transparent;
  border-bottom: ${({ noborder }: ItemProps) =>
    noborder ? '' : `1px solid ${NEW_LIGHT_GREY}`};

  margin: 0 auto;
  width: 95%;

  position: relative;
  cursor: pointer;

  height: auto;
  text-align: left;

  line-height: 1em;
  color: ${({ isActive }: ItemProps) =>
    isActive ? 'rgba(0,0,0,.95)' : 'rgba(0,0,0,.87)'};
  background: ${({ isActive }: ItemProps) =>
    isActive ? 'rgba(0,0,0,.03)' : ''};
  font-weight: ${({ isSelected }: ItemProps) => (isSelected ? 700 : '')};
`

const FeaturedImage = styled.img`
  height: 40px;
  width: 40px;
  margin-bottom: 0px;
  object-fit: cover;
  border-radius: 9px;
`

const ImageShadow = styled.div`
  background-color: ${LIGHT_GREY};
  height: 40px;
  width: 40px;
  border-radius: 9px;
`

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 10px;
`

const ItemName = styled.span`
  margin-bottom: 5px;
  font-size: 0.785rem;
`

const ItemCode = styled.p`
  margin: 0;
  color: ${GREY};
  font-size: 0.857rem;
`

const SearchBarWrapper = styled.div`
  width: 100%;
  margin: 15px;

  @media only screen and (min-width: ${ResponsiveBreakpoints.Small}) {
    width: 254px;
    margin: 0;
  }
`

const IconSubstitute = styled.div`
  width: 21px;
  height: 21px;
`

const SearchBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #5d5d5d;
  border-bottom-style: ${({ noborderbot }: SearchBarContainerProps) =>
    noborderbot ? 'none' : '1px solid #5d5d5d'};
  padding: 11.2px 5px;
  padding-bottom: ${({ noborderbot }: SearchBarContainerProps) =>
    noborderbot ? '12.2px' : '11.2px'};
  justify-content: space-around;

  > input {
    border-style: none;
    outline: 0;
    width: 70%;
  }
`

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-size: 0.8333rem;
`

const NoResultItem = styled.div`
  border-style: none !important;
  width: 100% !important;
  font-weight: bold;
  outline: 0;
  padding: 5px;
  margin-bottom: 0;
`

const ClearButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  :focus {
    outline-color: transparent;
    outline-style: none;
  }
`

const MenuContainer = styled.div`
  position: relative;
  z-index: ${zIndex.medium};
`

export {
  Menu,
  Item,
  NoResultItem,
  Label,
  SearchBarWrapper,
  SearchBarContainer,
  ClearButton,
  MenuContainer,
  IconSubstitute,
  FeaturedImage,
  ItemName,
  ImageShadow,
  ItemCode,
  DetailsContainer,
}

import styled from 'styled-components'
import CardContainer from '../../../Card/components/CardContainer'

const CardList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  > ${CardContainer} {
    margin-bottom: 1.5em;
  }
`

export default CardList

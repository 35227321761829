import * as React from 'react'
import styled from 'styled-components'
import ContactForm from '../ContactForm'
import Html from '../Html'
import Panel from '../Panel'

interface IHtmlForm {
  id: string
  html: string
  formPosition?: 'LEFT' | 'RIGHT'
}

const FullWidthFormTemplate = styled(ContactForm)`
  width: 100%;
`

type Props = IHtmlForm
const HtmlForm = ({ id, html, formPosition = 'RIGHT' }: Props) => {
  return (
    <Panel reverse={formPosition === 'LEFT'}>
      <section>
        <Html html={html} />
      </section>

      <section>
        <FullWidthFormTemplate id={id} />
      </section>
    </Panel>
  )
}

export default HtmlForm

import { colours } from '@awdis/components'
import * as React from 'react'
import styled from 'styled-components'

type DistributorAddressProps = {
  className?: string
  postcode?: string
  street?: string
  city?: string
}

const defaultProps = {}

const DistributorAddressC = function DistributorAddressC({
  className,
  postcode,
  street,
  city,
  ...props
}: DistributorAddressProps) {
  const bottomLine = [postcode, city].filter(exists => !!exists).join(' ')
  return (
    <div className={className} {...props}>
      {street && <div>{street}</div>}
      {bottomLine && <div>{bottomLine}</div>}
    </div>
  )
}

DistributorAddressC.defaultProps = defaultProps

const DistributorAddress = styled(DistributorAddressC)`
  color: ${colours.GREY};
  font-size: 0.9rem;
`

export default DistributorAddress

import { colours } from '@awdis/components'
import * as React from 'react'
import styled from 'styled-components'

type Props = {
  html: string
}

const HtmlWrapper = styled.div`
  margin: 20px 0 0 0;
  h1 {
    font-weight: 600;
    font-size: 1.375rem;
  }
  p {
    font-size: 1rem;
    color: ${colours.GREY};
  }
`

const Html = ({ html }: Props) => (
  <HtmlWrapper dangerouslySetInnerHTML={{ __html: html }} />
)

export default Html

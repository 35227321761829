import * as React from 'react'
import styled from 'styled-components'
import { properties, config } from '@awdis/components'

import ContactRedirectItem from '../ContactRedirectItem'

const { ResponsiveBreakpoints } = properties

const RedirectItems = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 32px;
  padding-top: 32px;
  padding-bottom: 48px;

  @media (min-width: ${ResponsiveBreakpoints.Small}) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export type ContactRedirectItemsProps = {
  className?: string
  style?: React.CSSProperties
}

const ContactRedirectItems: React.FC<ContactRedirectItemsProps> = props => {
  return (
    <RedirectItems {...props}>
      <ContactRedirectItem
        title="I am an individual looking for an item"
        buttonText="Contact us"
        redirectTo="/contact/individual/select-quantity"
      />

      <ContactRedirectItem
        title="I need a printer or embroiderer to promote my brand/club"
        buttonText="Contact us"
        redirectTo="/contact/embroiderer"
      />

      <ContactRedirectItem
        title={
          config.brand.brandDialect === 'US'
            ? 'I am a business that customizes garments looking for an approved distributor that sells your products'
            : 'I am a business that customises garments looking for an approved distributor that sells your products'
        }
        buttonText="Contact us"
        redirectTo="/contact/business"
      />
    </RedirectItems>
  )
}

export default ContactRedirectItems

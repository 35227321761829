import * as React from 'react'

import Html from '../Html'
import Section from '../Section'
import Panel from '../Panel'

interface IHtmlImage {
  html: string
  title: string
  imagePosition: 'LEFT' | 'RIGHT'
  backgroundColour?: string
  image?: string
}

type Props = IHtmlImage

const HtmlImage = ({
  html,
  backgroundColour,
  image,
  title,
  imagePosition,
}: Props) => {
  return (
    <Panel reverse={imagePosition === 'LEFT'} alignItems="stretch">
      <section>
        <Html html={html} />
      </section>
      <section>
        <Section
          image={image}
          backgroundColour={backgroundColour}
          title={title}
          thickness="fill"
        />
      </section>
    </Panel>
  )
}

export default HtmlImage

import axios from 'axios'

const { IPSTACK } = process.env

const IPIFY_URL = 'https://api.ipify.org'

let ipRef: string | null
export const getClientIP = async (): Promise<NonNullable<typeof ipRef>> => {
  if (!ipRef) {
    try {
      const {
        data: { ip },
      } = await axios.get(`${IPIFY_URL}?format=json`)

      ipRef = ip
    } catch (err) {
      console.error(err)
    }
  }

  return ipRef
}

let countryCodeRef: string | null
export const getCountryCode = async (
  ip: string
): Promise<NonNullable<typeof countryCodeRef>> => {
  if (!countryCodeRef) {
    try {
      const { data } = await axios({
        method: 'get',
        url: `https://api.ipstack.com/${ip}?access_key=${IPSTACK}`,
        responseType: 'stream',
      })

      const code = data.country_code

      countryCodeRef = code
    } catch (err) {
      console.error(err)
    }
  }

  return countryCodeRef
}

let geocoderRef: google.maps.Geocoder | null
const locationCache: { [countryCode: string]: { lat: number; lng: number } } = {
  europe: { lat: 48.132736, lng: 4.1751344 },
  US: { lat: 37.2756492, lng: -104.6561769 },
}
export const getAddressLocation = async (
  countryCode: string
): Promise<{ lat: number; lng: number }> => {
  if (!geocoderRef) {
    geocoderRef = new google.maps.Geocoder()
  }

  if (!locationCache[countryCode]) {
    const { results } = await geocoderRef.geocode({
      address: '',
      componentRestrictions: { country: countryCode },
    })
    if (!results[0]) {
      throw new Error(`Could not find location for address '${countryCode}'`)
    }

    const {
      geometry: { location },
    } = results[0]
    locationCache[countryCode] = { lat: location.lat(), lng: location.lng() }
  }

  return locationCache[countryCode]
}

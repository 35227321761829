import * as React from 'react'
import Panel from '../Panel'
import Html from '../Html'

interface IHtmlHtml {
  leftHtml: string
  rightHtml: string
}

type Props = IHtmlHtml

const HtmlHtml = ({ leftHtml, rightHtml }: Props) => {
  return (
    <Panel>
      <section>
        <Html html={leftHtml} />
      </section>
      <section>
        <Html html={rightHtml} />
      </section>
    </Panel>
  )
}

export default HtmlHtml

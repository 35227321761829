import { TextBlock, Title } from '@awdis/components'
import * as React from 'react'
import TextContainer from '../TextContainer'

type Props = {
  title: string
  text?: string
  image: string
  mobileImage?: string
}

/**
 * Page Top Header Component
 */
const Header = ({ title, text, image }: Props) => {
  return (
    <TextContainer
      alignItems="centre"
      column
      backgroundImage={image}
      thickness="thick"
      fill
      contentPosition="centre"
    >
      <Title size="big" inverted align="center">
        {title}
      </Title>
      {text && <TextBlock big>{text}</TextBlock>}
    </TextContainer>
  )
}

export default Header

import styled, { css } from 'styled-components'
import { colours } from '@awdis/components'

export const secondaryTextStyle = css`
  color: ${colours.GREY};
  font-size: 0.9rem;
  opacity: 0.75;
`

const DistributorPhone = styled.div`
  ${secondaryTextStyle}
`

export default DistributorPhone

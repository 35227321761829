import { SemanticLink, Title, properties } from '@awdis/components'
import * as React from 'react'
import styled from 'styled-components'
import { checkIsInternalUrl } from '../../services/utils/check'
import RouterLinkButton from '../RouterLinkButton'
import SemanticRouterLink from '../SemanticRouterLink'
import TextContainer from '../TextContainer'

const { ResponsiveBreakpoints } = properties

type Props = {
  title?: string
  buttonUrl?: string
  buttonText?: string
  backgroundImage?: string
  backgroundColour?: string
}

const defaultProps = {
  backgroundColour: '#353535',
}

const ExtendedText = styled(Title)`
  @media (min-width: ${ResponsiveBreakpoints.Small}) {
    margin-bottom: 0;
  }
`

const ActionButton = styled(RouterLinkButton)`
  margin-left: 1em;
`

const CallToAction = ({
  title,
  buttonText,
  buttonUrl,
  backgroundImage,
  ...props
}: Props) => {
  const isInternalUrl = buttonUrl && checkIsInternalUrl(buttonUrl)

  const content = (
    <TextContainer
      padding="20px"
      thickness="thick"
      backgroundImage={backgroundImage}
      {...props}
    >
      {title && (
        <ExtendedText size="big" textShadow inverted>
          {title}
        </ExtendedText>
      )}
      {buttonUrl && buttonText && (
        <ActionButton to={buttonUrl}>{buttonText}</ActionButton>
      )}
    </TextContainer>
  )

  let LinkComponent: React.ComponentType<any>
  let linkProps

  if (isInternalUrl) {
    LinkComponent = SemanticRouterLink
    linkProps = { to: buttonUrl }
  } else {
    LinkComponent = SemanticLink
    linkProps = { href: buttonUrl }
  }

  return buttonUrl && !buttonText ? (
    <LinkComponent {...linkProps} fluid>
      {content}
    </LinkComponent>
  ) : (
    content
  )
}

CallToAction.defaultProps = defaultProps

export default CallToAction

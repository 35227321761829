import { properties } from '@awdis/components'
import styled from 'styled-components'
import TextContainer from '../TextContainer'

type Props = {
  alignItems?: string
  reverse?: boolean
}

const Panel = styled.div<Props>`
  align-items: ${({ alignItems = 'flex-start' }) => alignItems};
  display: flex;
  flex-flow: row wrap;
  flex-direction: ${(props: Props) => (props.reverse ? 'row-reverse' : 'row')};
  justify-content: space-between;
  max-width: ${properties.MAIN_CONTAINER_WIDTH}px;
  margin: 40px -15px;

  @media (max-width: 950px) {
    justify-content: center;
  }

  section {
    display: flex;
    flex: 1 1;
    min-width: 400px;
    text-align: left;
    font-family: Suisse;
    color: #292929;
    font-size: 1rem;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 0 15px;
    @media (max-width: 450px) {
      width: 100%;
      min-width: 320px;
      height: auto;
    }
  }
  ${TextContainer} {
    min-height: 300px;
  }
`

export default Panel

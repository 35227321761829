import * as React from 'react'

import MenuItems from './components/MenuItems'
import { FirstUlContainer } from './styles'
import { Menu } from '../../../../../../services/types/menu'

type Props = {
  styles?: {
    opacity?: number
    display?: string
  }
  isNewIn?: boolean
  menu: Array<Menu>
}

const NavBar = ({ styles, menu, isNewIn }: Props) => (
  <FirstUlContainer style={styles}>
    <MenuItems menu={menu} isNewIn={isNewIn} />
  </FirstUlContainer>
)

export default NavBar

import { properties } from '@awdis/components'
import * as React from 'react'
import styled from 'styled-components'

const { ResponsiveBreakpoints } = properties

type Props = {
  alignItems?: string
  alignSelf?: string
  backgroundColour?: string
  backgroundImage?: string
  className?: string
  column?: boolean
  fill?: boolean
  gradient?: boolean
  padding?: string
  thickness?: 'thin' | 'medium' | 'thick' | 'fill'
  contentPosition?: 'top' | 'centre' | 'bottom'
  style?: React.CSSProperties
}

const TextContainer = styled.div.attrs<Props>(
  ({ backgroundImage, style = {} }) => ({
    style: {
      backgroundImage: `url("${backgroundImage}")`,
      ...style,
    },
  })
)`
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  align-items: ${({ alignItems = 'center' }: Props) => alignItems};
  align-self: ${({ alignSelf = 'center' }: Props) => alignSelf};

  flex-wrap: wrap;

  display: flex;
  flex-direction: ${(props: Props) => (props.column ? 'column' : 'row')};
  height: ${({ thickness }: Props) => {
    switch (thickness) {
      case 'thin': {
        return '50px'
      }

      case 'medium': {
        return '150px'
      }

      case 'thick': {
        return '300px'
      }

      case 'fill': {
        return '100%'
      }

      default: {
        return 'auto'
      }
    }
  }};
  justify-content: ${({ contentPosition = 'centre' }) => {
    switch (contentPosition) {
      case 'bottom': {
        return 'flex-end'
      }

      case 'centre': {
        return 'center'
      }

      case 'top': {
        return 'flex-start'
      }

      default: {
        return 'center'
      }
    }
  }};
  padding: ${(props: Props) => props.padding};
  width: ${({ fill = true }: Props) => (fill ? '100%' : 'auto')};
  margin-bottom: 20px;
  &:hover {
    > div {
      top: 0;
      opacity: 1;
    }
  }
  @media (max-width: ${ResponsiveBreakpoints.Small}) {
    flex-direction: column;
  }
`

export default TextContainer

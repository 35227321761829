export enum TemplateType {
  Header = 'HEADER',
  Carousel = 'CAROUSEL',
  Card = 'CARD',
  DistributorAccordions = 'DISTRIBUTOR_ACCORDIONS',
  DistributorMap = 'DISTRIBUTOR_MAP',
  Accordion = 'ACCORDION',
  Image = 'IMAGE',
  Html = 'HTML',
  HtmlImage = 'HTML_IMAGE',
  HtmlHtml = 'HTML_HTML',
  HtmlCards = 'HTML_CARDS',
  HtmlForm = 'HTML_FORM',
  HtmlAccordions = 'HTML_ACCORDIONS',
  CallToAction = 'CALL_TO_ACTION',
  Form = 'FORM',
  SizeGuide = 'SIZE_GUIDE',
  HowToBuy = 'HOW_TO_BUY',
  ImageImage = 'IMAGE_IMAGE',
  ResourceCards = 'RESOURCE_CARDS',
}

export interface IPageTemplate {
  id: string
  name: string
  type: TemplateType
  props?: object
  htmlClasses?: Array<string>
  htmlId?: string
}

export interface IPage {
  id: string
  title: string
  url: string
  sections: Array<IPageTemplate>
}

import * as React from 'react'
import styled from 'styled-components'

import AccordionTemplate, {
  IAccordionTemplate,
} from '../../../AccordionTemplate'

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

type Props = {
  accordions: Array<IAccordionTemplate>
}

const AccordionList = ({ accordions }: Props) => {
  return (
    <ListWrapper>
      {accordions.map((acc: IAccordionTemplate) => (
        <AccordionTemplate key={acc.title} {...acc} />
      ))}
    </ListWrapper>
  )
}

export default AccordionList

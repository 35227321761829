import * as React from 'react'

import Html from '../Html'
import Panel from '../Panel'
import AccordionList from './components/AccordionList'
import { IAccordionTemplate } from '../AccordionTemplate'

type Props = {
  html: string
  accordions: Array<IAccordionTemplate>
}

const HtmlAccordions = ({ html, accordions }: Props) => {
  return (
    <Panel>
      <section>
        <Html html={html} />
      </section>

      <section>
        <AccordionList accordions={accordions} />
      </section>
    </Panel>
  )
}

export default HtmlAccordions

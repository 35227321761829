import { ButtonProps, buttonStyle } from '@awdis/components'
import styled, { css } from 'styled-components'

export const linkButtonStyle = css`
  ${buttonStyle};

  display: inline-block;
  text-decoration: none;
`

const LinkButton = styled.a<ButtonProps>`
  ${(props: ButtonProps) => props && linkButtonStyle};
`

export default LinkButton
